.content {
  &__product {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, calc(calc(100vh - 200px) / 3));
    column-gap: 40px;
    &--suggested {
      position: relative;
    }
  }
  &__topping,
  &__suggested {
    // padding: 40px;
    padding: 40px;
    grid-column: 1 / -1;
  }
  &__galery {
    display: grid;
    grid-template-columns: repeat(3, 4fr);
  }
}

.product {
  &__photo,
  &__detail {
    grid-column-start: auto;
    grid-column-end: span 6;
  }
  &__photo {
    padding: 40px 20px 40px 40px;
  }
  &__detail {
    padding: 40px 40px 40px 20px;
  }
  &__title {
    color: #000000;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  &__price {
    display: inline-block;
    font-family: 'SerifaBoldBT';
    background-color: #00a0d1;
    color: white;
    padding: 8px 32px;
    font-size: 32px;
  }
}

.title {
  font-family: 'SerifaBoldBT';
  font-size: 60px;
  color: #00a0d1;
  margin-top: 0;
}
