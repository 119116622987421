.empty {
  &__state {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 200px);
    width: 100%;

    img {
      filter: grayscale(1);
      height: 400px;
    }

    span {
      font-size: 40px;
    }
  }
}
