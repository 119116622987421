.modal {
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__content {
    background-color: white;
    width: 80%;
    height: auto;
    border: 5px solid white;
    border-radius: 20px;
    img {
      border-radius: 20px;
    }
  }
  &__header {
    position: relative;
    button {
      position: absolute;
      right: -30px;
      top: -30px;
      z-index: 1;
      height: 60px;
      width: 60px;
      border-radius: 30px;
      border: 5px solid rgb(255, 255, 255);
      background-color: white;
      box-shadow: 0 4px 4px rgb(121, 121, 121);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 30px;
      }
    }
  }
}
