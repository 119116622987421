.home {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    overflow-y: hidden;
  }
  &__header {
    position: fixed;
    width: 100%;
    height: 200px;
    top: 0;
    background: #00a0d1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 200px;
    }
  }
  &__video {
    width: 100%;
  }
}
