@font-face {
  font-family: 'SerifaBoldBT';
  src: url('../fonts/SerifaBoldBT.ttf') format('truetype');
}
@font-face {
  font-family: 'SerifaBT';
  src: url('../fonts/SerifaBT.ttf') format('truetype');
}

$columns: 12;

::-webkit-scrollbar {
  display: none;
}

.d-none {
  display: none;
}

.mode-debug {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 70px;
  border: none;
  padding-right: 10px;
  border-radius: 20px 0 0 20px;
  position: fixed;
  right: 0;
  top: 200px;
  z-index: 2;
  background-color: rgb(224, 71, 71);
  &:hover {
    background-color: rgb(209, 46, 46);
  }
  img {
    width: 70%;
  }
}

.more__products {
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: 0.2s linear;
  z-index: 2;

  span {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: #1ab5e4;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      background-color: #1ab5e4;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 40px;
        transform: rotate(-90deg);
      }
    }
  }
}

.pulse {
  overflow: initial !important;
  position: relative !important;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  transition: opacity 0.3s, transform 0.3s;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.24, 1) infinite;
  z-index: -1;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(1.4);
  }

  to {
    opacity: 0;
    transform: scale(1.4);
  }
}

.loading-update {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  z-index: 1;
  background-color: white;
}
.logo-loading {
  height: 200px;
  animation-duration: 3s;
  animation-name: slidein;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  // transform: ;
}
@keyframes slidein {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.items {
  &__wrapper--categorie {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, calc(calc(100vh - 200px) / 4));
    align-items: stretch;

    .animated {
      height: 100%;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: stretch;
    column-gap: 4px;
    .animated {
      height: 100%;
      width: 100%;
    }
  }
  &__photo {
    width: 100%;
    height: 100%;
  }
  &__photo--product {
    width: 100%;
    height: calc(100% - 80px);
  }
  &__content {
    &--photo {
      position: relative;
      margin-left: 4px;
      height: 100%;
    }
  }
  &__name {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: #00a0d1;
    color: white;
    font-family: 'SerifaBoldBT';
    font-size: 28px;
  }
}

@mixin col-x {
  @for $i from 1 through $columns {
    .col-#{$i} {
      grid-column-start: auto;
      grid-column-end: span $i;
      height: auto;
    }
  }
}
// @mixin col-x {
//   $column-size: calc(100% / $columns);
//   @for $i from 1 through $columns {
//     .col-#{$i} {
//       width: $column-size * $i;
//     }
//   }
// }

@include col-x;
