* {
  outline: 0;
  text-decoration: none;
  color: #616161;
  font-family: 'SerifaBoldBT';
}
.register {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }

  &__loader {
    text-align: center;
    margin-bottom: 40px;
    p {
      margin: 0;
      font-size: 24px;
    }
  }

  &__content {
    text-align: center;
    h1 {
      font-size: 60px;
      margin: 0;
      color: #00a0d1;
    }
    span {
      font-size: 24px;
    }
  }
}
