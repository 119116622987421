.layout {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    // overflow: auto;
    // overflow-y: hidden;
  }
  &__header {
    background-color: #00a0d1;
    padding: 0 40px;

    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 200px;
      h2 {
        color: white;
        font-family: 'SerifaBoldBT';
        font-size: 60px;
      }
      button {
        background-color: transparent;
        border: none;
        outline: 0;
        cursor: pointer;
        img {
          max-width: 80px;
        }
      }
    }
  }
  &__footer {
    background-color: #ececec;
    padding: 20px 40px;
  }
}
.logo__categories {
  width: 100px;
}
.container {
  height: 100%;
}
